import React from 'react';
import RepurchaseConfirmationV1 from './RepurchaseConfirmationV1';

const RepurchaseConfirmation = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case 'version1':
      return <RepurchaseConfirmationV1 {...props} />;
  }
};

export default RepurchaseConfirmation;
